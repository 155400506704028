import React, { useEffect } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
} from "react-table";

import { Card, Table, Pagination, Row, Col, Form } from "react-bootstrap";
import generateExcel from "zipcelx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter }, gotoPage
}) {
  return (
    <Form.Control
      type="input"
      value={filterValue}
      onChange={(e) => {
        gotoPage(0);
        setFilter(e.target.value); // Set undefined to remove the filter entirely
      }}
      placeholder={``}
      className="mt-2"
    />
  );
}

export function filterTableHandle(element) {
  let maxLength = 40;
  let truncatedString = element.label.length > maxLength
    ? element.label.slice(0, maxLength - 3) + "..."
    : element.label;

  switch (element.type) {
    case 'textarea':
    case 'input':
      return {
        Header: truncatedString,
        accessor: element.label,
        filter: "includes",
      }
    case 'number':
      return {
        Header: truncatedString,
        accessor: element.label,
        Filter: NumberRangeColumnFilter,
        filter: "between",
      }
    case 'select':
      return {
        Header: truncatedString,
        accessor: element.label,
        Filter: SelectColumnFilter,
        filter: "includes",
      }
    case 'checkbox':
      return {
        Header: truncatedString,
        accessor: element.label,
        Filter: SelectColumnFilter,
        filter: "includes",
      }
    default:
      break;
  }
}

export function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div className="d-flex mt-2">
      <Form.Control
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "110px",
        }}
      />
      <span className="mx-2 mt-1">to</span>
      <Form.Control
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "110px",
        }}
      />
    </div>
  );
}

export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);
  return (
    <Form.Select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </Form.Select>
  );
}

export const dateFilter = (rows, id, filterValue) => {
  return rows.filter(row => {
    const rowDate = new Date(row.values[id]);
    const filterDate = new Date(filterValue);

    // Example: return rows where row date is the same as the filter date
    return rowDate.toDateString() === filterDate.toDateString();
  });
};

export const ColumnSortingTable = ({
  columns,
  data,
  getCellProps,
  title,
  setTriggerExport,
  triggerExport,
}) => {
  const filterTypes = React.useMemo(
    () => ({
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: ({ column, gotoPage }) => (
        <DefaultColumnFilter column={column} gotoPage={gotoPage} />
      ),
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0 },
      sortable: false,
      autoResetPage: false,
      autoResetFilters: false,
      autoResetSortBy: false
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  function getHeader(column) {
    const isReactElement = React.isValidElement(column.Header);

    if (isReactElement) {
      return [
        {
          value: "",
          type: "string",
        },
      ];
    }
    if (column.totalVisibleHeaderCount === 1) {
      return [
        {
          value: column.Header,
          type: "string",
        },
      ];
    } else {
      const span = [...Array(column.totalVisibleHeaderCount - 1)].map((x) => ({
        value: "",
        type: "string",
      }));
      return [
        {
          value: column.Header,
          type: "string",
        },
        ...span,
      ];
    }
  }

  function getExcel() {
    const config = {
      filename: title + ' - Histórico',
      sheet: {
        data: [],
      },
    };

    const dataSet = config.sheet.data;

    // review with one level nested config
    // HEADERS
    headerGroups.forEach((headerGroup) => {
      const headerRow = [];
      if (headerGroup.headers) {
        headerGroup.headers.forEach((column) => {
          headerRow.push(...getHeader(column));
        });
      }
      dataSet.push(headerRow);
    });

    // FILTERED ROWS
    if (rows.length > 0) {
      rows.forEach((row) => {
        const dataRow = [];
        Object.values(row.values).forEach((value) => {
          dataRow.push({
            value,
            type: typeof value === "number" ? "number" : "string",
          })
        }
        );
        dataSet.push(dataRow);
      });
    } else {
      dataSet.push([
        {
          value: "No data",
          type: "string",
        },
      ]);
    }
    return generateExcel(config);
  }

  useEffect(() => {
    if (triggerExport) {
      getExcel();
      setTriggerExport(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerExport]);

  return (
    <Card className="m-3">
      <Card.Body>
        <div className="overflow-auto">
          <Table striped bordered {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                      {column.canSort && (
                        <span onClick={(e) => {
                          e.stopPropagation(); // Prevent click from bubbling up to the th
                          column.toggleSortBy(); // Manually toggle the sort
                        }}>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <FontAwesomeIcon icon={faSortUp} className="ms-2" />
                            ) : (
                              <FontAwesomeIcon icon={faSortDown} className="ms-2" />
                            )
                          ) : (
                            <FontAwesomeIcon icon={faSort} className="ms-2" />
                          )}
                        </span>
                      )}
                      <div>
                        {column.canFilter ? column.render("Filter") : null}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.length > 0 ? (
                page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps([{}, getCellProps(cell)])}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              ) : (
                // Display a message or a row when there are no data
                <tr>
                  <td className="py-4" colSpan={columns.length} style={{ textAlign: "center" }}>
                    Não foi encontrado nenhum resultado
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        <Row className="text-start mt-2">
          <Col md="6">
            <span className="mx-2">
              Página{" "}
              <span>
                {pageIndex + 1} de {pageOptions.length}
              </span>
            </span>
            <span className="ms-3 me-2">Mostrar:</span>
            <Form.Select
              className="d-inline-block w-auto"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col md="6">
            <Pagination className="float-end mb-0">
              <Pagination.First
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              />
              <Pagination.Prev
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              />
              <Pagination.Next
                onClick={() => nextPage()}
                disabled={!canNextPage}
              />
              <Pagination.Last
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              />
            </Pagination>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
