import React from "react";
import { Navigate } from "react-router-dom";
// All pages that rely on 3rd party components (other than Bootstrap) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth
import Page500 from "./pages/auth/Page500";
import Page404 from "./pages/auth/Page404";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";

// Protected routes
import Events from "./pages/protected/Events";
import Applications from "./pages/protected/Applications";
import Users from "./pages/protected/Users";
import Logs from "./pages/protected/Logs";
import GuestGuard from "./components/guards/GuestGuard";

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Events />,
      },
      {
        path: "/eventos",
        element: <Events />,
      },
      {
        path: "aplicativos",
        element: <Applications />,
      },
      {
        path: "usuarios",
        element: <Users />,
      },
      {
        path: "logs",
        element: <Logs />,
      }
    ],
  },
  {
    path: "login",
    element:
      <GuestGuard>
        <AuthLayout />
      </GuestGuard>,
    children: [
      {
        path: "",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/" replace />
  }
];

export default routes;
