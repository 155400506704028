
import React, { useState, useContext } from "react";
import GoogleFontLoader from "react-google-font-loader";
import { confirmAlert } from "react-confirm-alert";
import axios from 'axios'
import { ShowConfirm } from "../../components/Alerts.js";
import useAuth from "../../hooks/useAuth";
import useLayout from "../../hooks/useLayout.js";
import {
  Container
} from "react-bootstrap";
import NotyfContext from "../../contexts/NotyfContext.js";
import {
  FormType, AplicativosType,
  FormComponentMode01, FormComponentMode02, SocialMediaType, ExtraSectionsForm
} from "../../components/Events.js";
import { EventHistory01, EventHistory02 } from './EventHistory'

export const EventEdit = ({
  dataTable,
  fonts,
  aplicativos, }) => {

  if (dataTable.platform === 1) {
    return (
      <EventEditMode01
        dataTable={dataTable}
        fonts={fonts}
        aplicativos={aplicativos}>
      </EventEditMode01>
    );
  } else if (dataTable.platform === 2) {
    return (
      <EventEditMode02
        dataTable={dataTable}
        fonts={fonts}>
      </EventEditMode02>
    );
  }
}

const EventEditMode01 = ({
  dataTable,
  fonts,
  aplicativos
}) => {
  const userState = useAuth();
  const [applications, setApplications] = useState(dataTable.aplicativos);
  const [dataAplicativo, setDataAplicativo] = useState(false);
  const [dataAddForm, setDataAddForm] = useState({ fields: dataTable.json.form.fields, fieldsToShow: dataTable.json.form.fieldsToShow ? dataTable.json.form.fieldsToShow : [], socialMedia: dataTable.json.layout.footer.socialMedia ? dataTable.json.layout.footer.socialMedia : [] });
  const [showModalAplicativos, setShowModalAplicativos] = useState(false);
  const [showModalForm, setShowModalForm] = useState(false);
  const [showSocialMediaForm, setShowSocialMediaForm] = useState(false);
  const [modalForm, setModalForm] = useState(false);
  const [updateDataTable, setDataTableUpdate] = useState(false);
  const [showHistory, setShowHistory] = useState(dataTable.platform === 1 ? userState.plataforma_aplicativos === 1 ? false : true : userState.plataforma_eventos === 1 ? false : true);
  const { setLoading } = useLayout();
  const notyf = useContext(NotyfContext);


  function handleDelete(props, text) {
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <ShowConfirm
            title="Confirmar ação"
            description={text}
            action={() => {
              deleteHistory(props);
              onClose();
            }}
            onClose={onClose}
          />
        );
      }
    });
  }

  function deleteHistory(props) {
    let url = ''
    switch (props.type) {
      case 'removeUsers':
        url = '/api/event/participants'
        break;
      case 'removeUsersHistory':
        url = '/api/event/participants/history'
        break;
      case 'removeUsersResults':
        url = '/api/event/result'
        break;

      default:
        setLoading(false)
        notyf.open({
          type: "danger",
          message: "Houve um erro, tente novamente",
          ripple: true,
          dismissible: true,
        });
        return
    }
    setLoading(true)
    axios.delete(url, { params: props })
      .then(function (response) {
        notyf.open({
          type: "success",
          message: "Exclusão realizada com sucesso",
          ripple: true,
          dismissible: true,
        });
        setDataTableUpdate((prevCount) => prevCount + 1);
      })
      .catch(function (error) {
        console.log(error)
        setLoading(false)
        notyf.open({
          type: "danger",
          message: "Houve um erro, tente novamente",
          ripple: true,
          dismissible: true,
        });
      });
  }

  if (showHistory) {
    return (
      <EventHistory01
        dataTable={dataTable}
        setShowHistory={setShowHistory}
        handleDelete={handleDelete}
        updateDataTable={updateDataTable}
        setDataTableUpdate={setDataTableUpdate}>
      </EventHistory01>
    );
  } else {
    return (
      <React.Fragment>
        <Container className="p-0">
          <GoogleFontLoader fonts={fonts} />
          <FormComponentMode01
            fonts={fonts}
            dataTable={dataTable}
            dataAddForm={dataAddForm}
            setShowHistory={setShowHistory}
            setDataAddForm={setDataAddForm}
            applications={applications}
            setApplications={setApplications}
            setDataAplicativo={setDataAplicativo}
            setModalForm={setModalForm}
            setShowModalAplicativos={setShowModalAplicativos}
            setShowModalForm={setShowModalForm}
            setShowSocialMediaForm={setShowSocialMediaForm}>
          </FormComponentMode01>
        </Container >
        {
          showModalAplicativos && (
            <AplicativosType
              dataTable={dataTable}
              setApplications={setApplications}
              dataAplicativo={dataAplicativo}
              setDataAplicativo={setDataAplicativo}
              showModalAplicativos={showModalAplicativos}
              setShowModalAplicativos={setShowModalAplicativos}
              aplicativos={aplicativos}
            ></AplicativosType>
          )
        }
        {
          showModalForm && (
            <FormType
              platform={dataTable.platform}
              dataAddForm={dataAddForm}
              setDataAddForm={setDataAddForm}
              modalForm={modalForm}
              setModalForm={setModalForm}
              showModalForm={showModalForm}
              setShowModalForm={setShowModalForm}
            />
          )
        }
        {
          showSocialMediaForm && (
            <SocialMediaType
              platform={dataTable.platform}
              setDataAddForm={setDataAddForm}
              modalForm={modalForm}
              setModalForm={setModalForm}
              showSocialMediaForm={showSocialMediaForm}
              setShowSocialMediaForm={setShowSocialMediaForm}
            />
          )
        }
      </React.Fragment >
    );
  }
};

const EventEditMode02 = ({
  dataTable,
  fonts
}) => {
  let initialPageSection = [{
    "value": "pagina-inicial",
    "label": "Página inicial",
    "href": "/",
    "icon": "faHome",
    "title": "Página inicial",
    "displayOnFooter": true
  }]
  const userState = useAuth();
  const [dataAddForm, setDataAddForm] = useState({ activeTab: 'pagina-inicial', tabs: dataTable.json.sections.length > 0 ? dataTable.json.sections : initialPageSection, fields: dataTable.json.form.fields, fieldsToShow: dataTable.json.form.fieldsToShow ? dataTable.json.form.fieldsToShow : [], socialMedia: dataTable.json.layout.footer.socialMedia });
  const [showModalForm, setShowModalForm] = useState(false);
  const [showSocialMediaForm, setShowSocialMediaForm] = useState(false);
  const [showExtraSectionsForm, setShowExtraSectionsForm] = useState(false);
  const [updateDataTable, setDataTableUpdate] = useState(false);
  const [modalForm, setModalForm] = useState(false);
  const { setLoading } = useLayout();
  const notyf = useContext(NotyfContext);
  const [showHistory, setShowHistory] = useState(dataTable.platform === 1 ? userState.plataforma_aplicativos === 1 ? false : true : userState.plataforma_eventos === 1 ? false : true);

  function handleDelete(props, text) {
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <ShowConfirm
            title="Confirmar ação"
            description={text}
            action={() => {
              deleteHistory(props);
              onClose();
            }}
            onClose={onClose}
          />
        );
      }
    });
  }

  function deleteHistory(props) {
    let url = ''
    switch (props.type) {
      case 'removeUsersMod':
      case 'removeUsers':
        url = '/api/event/participants'
        break;
      case 'removeUsersHistory':
      case 'removeUsersModHistory':
        url = '/api/event/participants/history'
        break;
      case 'removeUsersResults':
        url = '/api/event/result'
        break;

      default:
        setLoading(false)
        notyf.open({
          type: "danger",
          message: "Houve um erro, tente novamente",
          ripple: true,
          dismissible: true,
        });
        return
    }
    console.log(props)
    setLoading(true)
    axios.delete(url, { params: props })
      .then(function (response) {
        notyf.open({
          type: "success",
          message: "Exclusão realizada com sucesso",
          ripple: true,
          dismissible: true,
        });
        setDataTableUpdate((prevCount) => prevCount + 1);
      })
      .catch(function (error) {
        console.log(error)
        setLoading(false)
        notyf.open({
          type: "danger",
          message: "Houve um erro, tente novamente",
          ripple: true,
          dismissible: true,
        });
      });
  }

  if (showHistory) {
    return (
      <EventHistory02
        dataTable={dataTable}
        setShowHistory={setShowHistory}
        handleDelete={handleDelete}
        updateDataTable={updateDataTable}
        setDataTableUpdate={setDataTableUpdate}>
      </EventHistory02>
    );
  } else {
    return (
      <React.Fragment>
        <Container className="p-0">
          <GoogleFontLoader fonts={fonts} />
          <FormComponentMode02
            fonts={fonts}
            dataTable={dataTable}
            dataAddForm={dataAddForm}
            setShowHistory={setShowHistory}
            setDataAddForm={setDataAddForm}
            setModalForm={setModalForm}
            setShowModalForm={setShowModalForm}
            setShowSocialMediaForm={setShowSocialMediaForm}
            showExtraSectionsForm={showExtraSectionsForm}
            setShowExtraSectionsForm={setShowExtraSectionsForm}>
          </FormComponentMode02>
        </Container>
        {
          showModalForm && (
            <FormType
              platform={dataTable.platform}
              dataAddForm={dataAddForm}
              setDataAddForm={setDataAddForm}
              modalForm={modalForm}
              setModalForm={setModalForm}
              showModalForm={showModalForm}
              setShowModalForm={setShowModalForm}
            />
          )
        }
        {
          showSocialMediaForm && (
            <SocialMediaType
              platform={dataTable.platform}
              setDataAddForm={setDataAddForm}
              modalForm={modalForm}
              setModalForm={setModalForm}
              showSocialMediaForm={showSocialMediaForm}
              setShowSocialMediaForm={setShowSocialMediaForm}
            />
          )
        }
        {
          showExtraSectionsForm && (
            <ExtraSectionsForm
              dataTable={dataTable}
              platform={dataTable.platform}
              dataAddForm={dataAddForm}
              setDataAddForm={setDataAddForm}
              modalForm={modalForm}
              setModalForm={setModalForm}
              showExtraSectionsForm={showExtraSectionsForm}
              setShowExtraSectionsForm={setShowExtraSectionsForm}
            />
          )
        }
      </React.Fragment >
    );
  }
};
