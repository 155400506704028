import {
  faUserCog,
  faLayerGroup,
  faDisplay,
  faToiletPaper,
  faFileInvoiceDollar
} from "@fortawesome/free-solid-svg-icons";


const DashboardItems = (state) => {
  let navItems = [{
    title: "Menu",
    pages: [{
      href: "/",
      icon: faLayerGroup,
      title: "Eventos"
    }],
  }]
  if (state.aplicativos_customizacao === 1) {
    navItems[0].pages.push({
      href: "/aplicativos",
      icon: faDisplay,
      title: "Aplicativos"
    })
  }
  if (state.tipo === 1) {
    navItems[1] = {
      title: "Gerenciamento",
      pages: [{
        href: "/usuarios",
        icon: faUserCog,
        title: "Usuários"
      },
      {
        href: "/logs",
        icon: faToiletPaper,
        title: "Log de erros"
      }]
    }
    navItems[2] = {
      title: "Serviços",
      pages: [{
        href: "/orcamento-flex-2025",
        icon: faFileInvoiceDollar,
        title: "Gerar orçamentos"
      }]
    }
  }
  return navItems
}

export default DashboardItems;
