/* eslint-disable eqeqeq */
import React, { useEffect, useState, useContext } from "react";
import { format } from "date-fns-tz";
import { add, sub } from "date-fns";
import axios from "axios";
import {
  Container,
  Modal,
  Col,
  Form,
  Breadcrumb,
  Row,
  InputGroup,
  Tooltip,
  OverlayTrigger
} from "react-bootstrap";
import { ColumnSortingTable } from "../../components/Table.js";
import InputMask from "react-input-mask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useLayout from "../../hooks/useLayout.js";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FileText } from "react-feather";
import Loader from "../../components/Loader.js";
import NotyfContext from "../../contexts/NotyfContext.js";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  convertDateToDisplay
} from "../../assets/functions.js";


const tableColumns = [
  {
    Header: "Projeto",
    accessor: "projeto",
    Filter: false,
  },
  {
    Header: "ID",
    accessor: "id",
    Filter: false,
  },
  {
    Header: "Log",
    accessor: "json",
    Filter: false,
  },
  {
    Header: "Origem",
    accessor: "origem",
    Filter: false,
  },
  {
    Header: "Data",
    accessor: "data",
    Filter: false,
  },
  {
    Header: "",
    accessor: "icons",
    disableSortBy: true,
    Filter: false,
  },
];

const projects = ["Todos", "Plataforma de aplicativos", "Plataforma de eventos", "Painel de controle", "Cron"]

const TableLogs = () => {
  const [dataTable, setDataTable] = useState(false);
  const [updateDataTable, setDataTableUpdate] = useState(false);
  const [filter, setFilter] = useState({ project: 0, data_inicio: convertDateToDisplay(defaultInitialDate()), data_final: convertDateToDisplay(defaultEndDate()) });
  const notyf = useContext(NotyfContext);
  const { setLoading } = useLayout();

  //Modal data
  const [dataModal, setDataModal] = useState(false);

  //Modal handle
  const [openModals, setShowModal] = useState(false);

  function handleShow(data) {
    setDataModal(data);
    setShowModal(true);
  }

  function handleClose() {
    setDataModal(false);
    setShowModal(false);
  }

  useEffect(() => {
    if (dataTable) {
      setLoading(true)
    }
    axios
      .get(`/api/logs?project=${filter.project}&data_inicio=${filter.data_inicio}&data_final=${filter.data_final}`)
      .then(function (response) {
        var logs = response["data"]["message"];
        logs.forEach(function callback(element, index) {
          element.projeto = projects[element.projeto]
          if (element.erro) {
            // Check if the length exceeds 100 and proceed with substring if necessary
            if (element.erro.length > 100) {
              element.json = element.erro.substring(0, 125) + '...';
            } else {
              element.json = element.erro; // Assign the entire value if it's less than or equal to 100
            }
            try {
              while (typeof element.erro === "string") {
                element.erro = JSON.parse(element.erro);
              }
              console.log("Parsed object:", element.erro);
            } catch (error) {
              console.error("Error parsing JSON:", error.message);
            }
          }
          element.data = convertDateToDisplay(element.data)
          element.icons = (
            <OverlayTrigger overlay={<Tooltip>Ver detalhes</Tooltip>}>
              <FileText
                role="button"
                className="align-middle me-2 cursor-pointer"
                onClick={() => {
                  handleShow(element);
                }}
                size={18}
              />
            </OverlayTrigger>
          );
        });
        setLoading(false)
        setDataTable(logs);
      })
      .catch(function (error) {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDataTable]);

  //Loading icon
  if (dataTable === false || dataTable === undefined) {
    return <Loader></Loader>;
  }

  return (
    <Container fluid className="p-0 container">
      <Breadcrumb>
        <Breadcrumb.Item active>
          Logs
        </Breadcrumb.Item>
      </Breadcrumb>
      <h6 className="text-muted mb-0">
        Erros registrados nas APIs em vigência
      </h6>
      <Row className="mt-4 mb-0 mx-0">
        <Form.Group
          className="mb-sm-0 mb-3"
          as={Col}
          md="4"
          sm={12}
          controlId="validationFormik03">
          <Form.Label>Projeto</Form.Label>
          <Form.Select
            value={filter.project}
            onChange={(e) => {
              setFilter((prevState) => ({
                ...prevState,
                project: e.target.value
              }));
              setDataTableUpdate((prevCount) => prevCount + 1)
            }}
          >
            {projects.map((element, index) => {
              return (
                <option key={"status" + index} value={index}>
                  {element}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>

        <Form.Group
          className="mb-sm-0 mb-3"
          as={Col}
          md="4"
          sm={12}
          controlId="validationFormik06"
        >
          <Form.Label>Data de ínicio</Form.Label>
          <InputGroup>
            <InputMask
              mask="99/99/9999 99:99"
              value={filter.data_inicio}
              onChange={(e) => {
                console.log(e.target.value)
                setFilter((prevState) => ({
                  ...prevState,
                  data_inicio: e.target.value
                }))
              }}
            >
              {(inputProps) => (
                <Form.Control
                  {...inputProps}
                  type="text"
                  name="data_inicio"
                />
              )}
            </InputMask>
            <InputGroup.Text onClick={() => setDataTableUpdate((prevCount) => prevCount + 1)}>
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>

        <Form.Group
          className="mb-sm-0 mb-3"
          as={Col}
          md="4"
          sm={12}
          controlId="validationFormik06"
        >
          <Form.Label>Data de ínicio</Form.Label>
          <InputGroup>
            <InputMask
              mask="99/99/9999 99:99"
              value={filter.data_final}
              onChange={(e) => {
                console.log(e.target.value)
                setFilter((prevState) => ({
                  ...prevState,
                  data_final: e.target.value
                }))
              }}
            >
              {(inputProps) => (
                <Form.Control
                  {...inputProps}
                  type="text"
                  name="data_final"
                />
              )}
            </InputMask>
            <InputGroup.Text onClick={() => setDataTableUpdate((prevCount) => prevCount + 1)}>
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Row>

      <Row>
        <Col className="px-0" md={12}>
          <ColumnSortingTable
            columns={tableColumns}
            data={dataTable}
            getCellProps={(cellinfo) => ({
              className: cellinfo.column.id === "icons" ? "table-icons" : "",
              style: {
                maxWidth: cellinfo.column.id === "json" ? "500px" : "",
              },
            })}
          />
          <LogModal
            openModals={openModals}
            dataModal={dataModal}
            handleClose={handleClose}
          />
        </Col>
      </Row>
    </Container>
  );
};

const LogModal = ({
  openModals,
  dataModal,
  handleClose
}) => {
  // Check if dataModal is an object
  const isObject =
    dataModal.erro &&
    typeof dataModal.erro === "object" &&
    !Array.isArray(dataModal.erro);

  return (
    <React.Fragment>
      <Modal show={openModals} onHide={handleClose} size="large" centered>
        <Modal.Header closeButton>{`Visualizar log detalhado`}</Modal.Header>
        <Modal.Body>
          <h6 className="mb-1 modalDataFields">
            Número de identificação: <span className="opacity-75">{dataModal.id}</span>
          </h6>
          <h6 className="mb-1 modalDataFields">
            Projeto: <span className="opacity-75">{dataModal.projeto}</span>
          </h6>
          <h6 className="mb-1 modalDataFields">
            Função de origem <span className="opacity-75">{dataModal.origem}</span>
          </h6>
          <h6 className="mb-4 modalDataFields">
            Data de registro: <span className="opacity-75">{dataModal.data}</span>
          </h6>
          <h4 className="text-center">Log</h4>
          {isObject ? (
            // If dataModal is a valid object, map its values
            Object.entries(dataModal.erro).map(([key, value], index) => (
              <h6 className="mb-3 modalDataFields" key={index}>{key}:<span className="opacity-75">{value}</span></h6>
            ))
          ) : (
            // If not an object, display the raw text
            <h6 className="modalDataFields"><span className="opacity-75">{dataModal.erro || "No data available"}</span></h6>
          )}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export function defaultInitialDate() {
  const dataObj = new Date();
  const dataObjX = sub(dataObj, { days: "7" });
  const date = format(dataObjX, "yyyy-MM-dd HH:mm:ss");
  return date;
}

export function defaultEndDate() {
  const dataObj = new Date();
  const dataObjX = add(dataObj, { days: "7" });
  const date = format(dataObjX, "yyyy-MM-dd HH:mm");
  return date;
}

export default TableLogs;
